import { appApi } from './AppApi';
import { appFileUploadApi } from './AppMulitipartApi';
import { AppS3DownloadApi } from './AppS3DownloadApi';
import { AppS3StreamApi } from './AppS3StremApi';



const baseAiUrl = process.env.REACT_APP_AI_URL

export const translateApi = async (data) => await appFileUploadApi(`${baseAiUrl}process_audio`, "post", data)
