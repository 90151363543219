import { Navigate, useRoutes } from 'react-router-dom';
import * as C from './commonimport'

const routeList = [
  { path: '/', element: <Navigate to="translate" /> },
  { path: '/home', element: <C.Home /> },
  { path: '/translate', element: <C.Translate /> },
]

export default function Router() {
  const routes = useRoutes(routeList);
  return routes;
}
