import PropTypes from 'prop-types';

export default function Button({
    children,
    onClick,
    className = '',
    disabled = false
}) {

    const getClassName = () => {
        let returnClass = ' p-1 font-semibold rounded-md text-nowrap'
        returnClass += disabled ? ' opacity-40 ' : ''
        returnClass += className ? ` ${className} ` : ''
        return returnClass
    }
    return (
        <button
            className={` ${getClassName()}`}
            onClick={() => { if (!disabled) onClick() }}
            disabled={disabled}
            type="button"
        >
            {children}
        </button>
    )
}

Button.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};