import React, { useEffect, useState, useRef } from 'react';
import { Button, InputWithIcon, IconButton, TextAreaWithIcon } from 'src/components';
import { FiMic, FiMicOff } from "react-icons/fi";
import { Show } from 'src/helpers/Show';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { GrPowerReset } from "react-icons/gr";
import { MdSend, MdOutlineContentCopy } from "react-icons/md";
import { checkEmpty } from 'src/commonUtils';
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { TiTick } from "react-icons/ti";




const baseAiUrl = process.env.REACT_APP_AI_URL;

const Home = () => {
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const [isListening, setIsListening] = useState(false);
    const [chatAnswer, setChatAnswer] = useState('');
    const [chat, setChat] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [copy, setCopy] = useState(false)

    const responseRef = useRef(null); // Reference for auto-scrolling

    const handleListeningToggle = () => {
        if (isListening) {
            SpeechRecognition.stopListening();
        } else {
            SpeechRecognition.startListening({ continuous: true });
        }
        setIsListening(!isListening);
    };

    const handleListening = () => {
        setChat('')
        setChatAnswer('')
        resetTranscript()
    }

    const handleCopy = () => {
        const text = responseRef?.current?.innerText;
        navigator.clipboard.writeText(text).then(() => {
            setCopy(true)
            setTimeout(() => {
                setCopy(false)
            }, 1000)
        }).catch(err => {
            console.error("Failed to copy: ", err);
        });
    };


    useEffect(() => {
        if (isListening) {

            setChat(transcript);
            // resetTranscript()
        }
    }, [transcript]);

    useEffect(() => {
        // Scroll to the bottom of the chat text box when chatAnswer changes
        if (responseRef) {
            responseRef.current.scrollTop = responseRef.current.scrollHeight;
        }
    }, [chatAnswer]);

    const getAnswer = async () => {
        try {
            if (!isLoading) {
                setIsLoading(true);
                setChatAnswer('');
                const data = { "medical_description": chat };
                const response = await fetch(`${baseAiUrl}stream_soap`, {
                    method: 'POST',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(data)
                });

                if (!response.ok) throw new Error('Network response was not ok');

                const reader = response.body.getReader();
                const answerData = [];

                const readStream = async () => {
                    const { done, value } = await reader.read();
                    if (done) {
                        setIsLoading(false);
                        return;
                    }
                    const chunk = new TextDecoder().decode(value);
                    if (chunk) {
                        answerData.push(chunk);
                        setChatAnswer(answerData.join(' '));

                        // Scroll to the response container after updating the answer
                        if (responseRef.current) {
                            responseRef.current.scrollIntoView({ behavior: 'smooth' });
                        }
                    }
                    readStream();
                };
                readStream();
            }
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);
        }
    };

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    return (
        <div className='bg-backgroundPrimary min-h-screen p-4'>
            <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6 mb-5">
                <h1 className="text-2xl font-bold text-center mb-6">SOAP Notes Generator</h1>
                <div className="space-y-4">
                    <TextAreaWithIcon
                        value={chat}
                        rows={3}
                        endIcon={
                            <div className="flex items-center space-x-2">
                                <Show>
                                    <Show.When isTrue={isListening}>
                                        <FiMic onClick={handleListeningToggle} className="cursor-pointer text-xl text-green-700" />
                                    </Show.When>
                                    <Show.Else>
                                        <FiMicOff onClick={handleListeningToggle} className="cursor-pointer text-xl text-red-500" />
                                    </Show.Else>
                                </Show>
                                <GrPowerReset onClick={handleListening} className="cursor-pointer text-lg text-red-500 w-5 h-5 font-bold" />
                                <IconButton onClick={getAnswer} className="text-blue-500" disabled={!checkEmpty(chat) || isLoading || listening}>
                                    <MdSend className="text-2xl" />
                                </IconButton>
                            </div>
                        }
                        onChange={e => setChat(e.target.value)}
                        placeholder="Type or dictate the medical description..."
                        className="w-full"
                    />
                </div>

                {/* {isLoading && (
                    <div className="flex justify-center mt-4">
                        <img src="/assets/loader/loader.svg" alt="Loading..." className="loader-icon" />
                    </div>
                )} */}
            </div>

            <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
                <h1 className="text-2xl font-bold text-center mb-6">SOAP Notes</h1>
                <div className='w-full flex justify-end'>
                    <Button onClick={handleCopy} className='flex items-center gap-2  '>
                        {copy ?
                            <>
                                <TiTick className='text-green-600 w-5 h-5' />
                                <span className='text-green-600'>Copied</span>
                            </> :
                            <>
                                <MdOutlineContentCopy />
                                <span>Copy</span>
                            </>
                        }
                    </Button>
                </div>
                <div
                    className="p-4 bg-gray-50 border rounded-lg shadow overflow-auto"
                    ref={responseRef} // Attach the ref to this container for auto-scroll
                >
                    <Show>
                        <Show.When isTrue={checkEmpty(chatAnswer)}>
                            {chatAnswer.split('\n').map((text, index) => (
                                <p key={index} className="mb-2 text-gray-700">{text}</p>
                            ))}
                            {isLoading && (<HiOutlineDotsHorizontal className='text-gray-700' />)}
                        </Show.When>
                        <Show.Else>
                            <p className="mb-2 text-gray-700 flex justify-center">Here you can generate a soap notes...</p>
                        </Show.Else>
                    </Show>
                </div>
            </div>
        </div>
    );
};

export default Home;
