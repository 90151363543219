import React, { useEffect, useState, useRef } from 'react';
import { Button, FileUpload, IconButton, TextAreaWithIcon } from 'src/components';
import { FiMic, FiMicOff } from "react-icons/fi";
import { Show } from 'src/helpers/Show';
import { GrPowerReset } from "react-icons/gr";
import { MdSend, MdOutlineContentCopy } from "react-icons/md";
import { checkEmpty } from 'src/commonUtils';
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { TiTick } from "react-icons/ti";
import { translateApi } from 'src/apiUtils/apiPath';
import { CgAttachment } from "react-icons/cg";

const baseAiUrl = process.env.REACT_APP_AI_URL;

const Home = () => {
    const [audioURL, setAudioURL] = useState('');
    const [audioBLOB, setAudioBLOB] = useState('');
    const mediaRecorderRef = useRef(null);
    const [isListening, setIsListening] = useState(false);
    const [chatAnswer, setChatAnswer] = useState('');
    const [chat, setChat] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTranslate, setIsLoadingTranlate] = useState(false)
    const [copy, setCopy] = useState(false);
    const responseRef = useRef(null);

    const handleStartRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);

            const audioChunks = [];
            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunks.push(event.data);
                }
            };

            mediaRecorderRef.current.onstop = async () => {
                const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                setAudioBLOB(await resampleAudio(audioBlob)); // Resample the audio
                const url = URL.createObjectURL(audioBlob);
                setAudioURL(url);
            };

            mediaRecorderRef.current.start(); // Start recording
            setIsListening(true);
        } catch (error) {
            console.error('Error accessing microphone', error);
        }
    };

    const handleStopRecording = () => {
        if (mediaRecorderRef.current?.state === 'recording') {
            mediaRecorderRef.current.stop(); // Stop recording
            setIsListening(false);
        } else {
            console.warn("MediaRecorder is not recording or already stopped.");
        }
    };

    useEffect(() => {
        if (audioBLOB !== '') {
            translate();
        }
    }, [audioBLOB]);

    const resampleAudio = async (audioBlob) => {
        const arrayBuffer = await audioBlob.arrayBuffer(); // Convert Blob to ArrayBuffer
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        // Create OfflineAudioContext with 16000 Hz sample rate
        const offlineContext = new OfflineAudioContext(
            audioBuffer.numberOfChannels,
            audioBuffer.duration * 16000,
            16000
        );

        // Create a buffer source
        const bufferSource = offlineContext.createBufferSource();
        bufferSource.buffer = audioBuffer;
        bufferSource.connect(offlineContext.destination);
        bufferSource.start(0);

        // Render the resampled audio
        const resampledBuffer = await offlineContext.startRendering();

        // Convert resampled audio buffer to a Blob (WAV format)
        return bufferToWave(resampledBuffer, resampledBuffer.length);
    };

    // Helper function to convert AudioBuffer to WAV Blob
    const bufferToWave = (buffer, len) => {
        const numOfChan = buffer.numberOfChannels;
        const length = len * numOfChan * 2 + 44;
        const bufferArray = new ArrayBuffer(length);
        const view = new DataView(bufferArray);

        // Write WAV header
        let offset = 0;
        const writeString = (str) => {
            for (let i = 0; i < str.length; i++) {
                view.setUint8(offset + i, str.charCodeAt(i));
            }
            offset += str.length;
        };
        writeString('RIFF');
        view.setUint32(offset, 36 + len * 2, true); offset += 4;
        writeString('WAVE');
        writeString('fmt ');
        view.setUint32(offset, 16, true); offset += 4;
        view.setUint16(offset, 1, true); offset += 2;
        view.setUint16(offset, numOfChan, true); offset += 2;
        view.setUint32(offset, 16000, true); offset += 4; // Sample rate
        view.setUint32(offset, 16000 * 2, true); offset += 4;
        view.setUint16(offset, numOfChan * 2, true); offset += 2;
        view.setUint16(offset, 16, true); offset += 2;
        writeString('data');
        view.setUint32(offset, len * numOfChan * 2, true); offset += 4;

        // Write PCM samples
        for (let i = 0; i < len; i++) {
            for (let channel = 0; channel < numOfChan; channel++) {
                const sample = Math.max(-1, Math.min(1, buffer.getChannelData(channel)[i]));
                view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true);
                offset += 2;
            }
        }

        return new Blob([view], { type: 'audio/wav' });
    };

    const translate = async () => {
        setIsLoadingTranlate(true)
        try {
            const arrayBuffer = await audioBLOB.arrayBuffer(); // Convert Blob to ArrayBuffer
            const binaryData = new Uint8Array(arrayBuffer);    // Convert ArrayBuffer to binary data

            const formData = new FormData();
            formData.append('file', new Blob([binaryData], { type: 'audio/wav' }), 'audio.wav'); // Append binary data

            const res = await translateApi(formData);

            if (res.status) {
                setChat(res.entity);
                setIsLoadingTranlate(false)
                setAudioBLOB(''); // Clear the Blob after sending
            } else {
                setIsLoadingTranlate(false)
            }
        } catch (error) {
            setIsLoadingTranlate(false)
            console.error('Translation API error:', error);
        }
    };

    const getAnswer = async () => {
        try {
            if (!isLoading) {
                setIsLoading(true);
                setChatAnswer('');
                const data = { medical_description: chat };
                const response = await fetch(`${baseAiUrl}stream_soap`, {
                    method: 'POST',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(data),
                });

                if (!response.ok) throw new Error('Network response was not ok');

                const reader = response.body.getReader();
                const answerData = [];

                const readStream = async () => {
                    const { done, value } = await reader.read();
                    if (done) {
                        setIsLoading(false);
                        return;
                    }
                    const chunk = new TextDecoder().decode(value);
                    if (chunk) {
                        answerData.push(chunk);
                        setChatAnswer(answerData.join(' '));

                        if (responseRef.current) {
                            responseRef.current.scrollIntoView({ behavior: 'smooth' });
                        }
                    }
                    readStream();
                };
                readStream();
            }
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);
        }
    };

    const handleCopy = () => {
        const text = responseRef.current?.innerText || '';
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopy(true);
                setTimeout(() => setCopy(false), 1000);
            })
            .catch(err => console.error("Failed to copy:", err));
    };

    useEffect(() => {
        if (responseRef.current) {
            responseRef.current.scrollTop = responseRef.current.scrollHeight; // Auto-scroll
        }
    }, [chatAnswer]);

    return (
        <div className="bg-backgroundPrimary min-h-screen p-4">
            <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6 mb-5">
                <h1 className="text-2xl font-bold text-center mb-6">SOAP Notes Generator</h1>
                <div className="space-y-4">
                    <TextAreaWithIcon
                        value={chat}
                        rows={3}
                        endIcon={
                            <div className="flex items-center space-x-2">
                                <Show>
                                    <Show.When isTrue={isLoadingTranslate}>
                                        <img src="/assets/loader/loader.svg" alt="Loading..." className="loader-icon" />
                                    </Show.When>
                                    <Show.Else>
                                        <Show>
                                            <Show.When isTrue={isListening}>
                                                <FiMic onClick={handleStopRecording} className="cursor-pointer text-xl text-green-700" />
                                            </Show.When>
                                            <Show.Else>
                                                <FiMicOff onClick={handleStartRecording} className="cursor-pointer text-xl text-red-500" />
                                            </Show.Else>
                                        </Show>
                                        <GrPowerReset onClick={() => setChat('')} className="cursor-pointer text-lg text-red-500 w-5 h-5 font-bold" />
                                        <IconButton
                                            onClick={getAnswer}
                                            className="text-blue-500"
                                            disabled={!checkEmpty(chat) || isLoading || isListening}
                                        >
                                            <MdSend className="text-2xl" />
                                        </IconButton>
                                    </Show.Else>
                                </Show>
                                {/* <FileUpload validTypes={['audio/']} handleFilePreview={(e) => }>
                                <CgAttachment />
                                </FileUpload> */}
                            </div>
                        }
                        onChange={e => setChat(e.target.value)}
                        placeholder="Type or dictate the medical description..."
                        className="w-full"
                    />
                </div>
                {audioURL && (
                    <audio src={audioURL} controls className="mt-4" />
                )}
            </div>

            <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
                <h1 className="text-2xl font-bold text-center mb-6">SOAP Notes</h1>
                <div className="w-full flex justify-end">
                    <Button onClick={handleCopy} className="flex items-center gap-2">
                        {copy ? (
                            <>
                                <TiTick className="text-green-600 w-5 h-5" />
                                <span className="text-green-600">Copied</span>
                            </>
                        ) : (
                            <>
                                <MdOutlineContentCopy />
                                <span>Copy</span>
                            </>
                        )}
                    </Button>
                </div>
                <div className="p-4 bg-gray-50 border rounded-lg shadow overflow-auto" ref={responseRef}>
                    <Show>
                        <Show.When isTrue={checkEmpty(chatAnswer)}>
                            {chatAnswer.split('\n').map((text, index) => (
                                <p key={index} className="mb-2 text-gray-700">{text}</p>
                            ))}
                            {isLoading && (<HiOutlineDotsHorizontal className="text-gray-700" />)}
                        </Show.When>
                        <Show.Else>
                            <p className="mb-2 text-gray-700 flex justify-center">Here you can generate SOAP notes...</p>
                        </Show.Else>
                    </Show>
                </div>
            </div>
        </div>
    );
};

export default Home;
