const numericPattern = /^[0-9]*$/;
export const toProperCase = str =>
    str?.replace(/_/g, ' ')?.toLowerCase()?.replace(/\b\w/g, char => char.toUpperCase());

export const checkNumber = str => numericPattern.test(str)

export const getAwsFileName = (url) => url ? url.substring(url.lastIndexOf('/') + 1)?.replace(/%20/g, ' ') : '';

export const checkEmpty = (value) => value !== '' && value !== null && value !== undefined && value !== 'undefined' && value !== 'null'

export const CheckImageIsUploaded = (file1, file2) => ((file1 !== '' && file1 !== null && file1 !== undefined && file1 !== 'undefined' && file1 !== 'null') || (file2 !== '' && file2 !== null && file2 !== undefined && file2 !== 'undefined' && file2 !== 'null'))